import React from 'react';
import { useInView } from 'react-intersection-observer';

const AnimatedComponent = ({ animation, id, children, delay = '0s', duration = '1s' }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const style = {
    animationDelay: delay,
    animationDuration: duration,
  };

  return (
    <>
    {/* <div ref={ref} id={id} className={`animate__animated ${inView ? animation : ''}`}>
      {children}
    </div> */}
    
    <div
      ref={ref}
      id={id}
      className={`animate__animated ${inView ? animation : ''}`}
      style={style}
    >
      {children}
    </div>
    </>
  );
};

export default AnimatedComponent;