import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "./footer/Footer";

import '../pages/news/news.css'

const NewsDetails = () => {

  const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [image, setImage] = useState();
  const [seoTitle, setSeoTitle] = useState();
  const [seoDescription, setSeoDescription] = useState();
  const [seoKeyword, setSeoKeyword] = useState();
  const [relatedNews, setRelatedNews] = useState();
  const urlParts = window.location.href.split("/");
  const blogSlug = urlParts[urlParts.length - 1];
  const [blogData, setBlogData] = useState([]);
  const navigate = useNavigate();
  const [contentLoaded, setContentLoaded] = useState(false);
  const [actualSlide, setActualSlide] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = `https://backend.groups.abreco.nvds.in/api/news/${blogSlug}`;

    const blogMain = async () => {
      const { data } = await axios.get(apiUrl);
      const title = data.data.news.title;
      setTitle(title);
      const content = data.data.news.description;
      setContent(content);
      const image = data.data.news.image;
      setImage(image);
      const seoTitle = data.data.news.seo_title;
      setSeoTitle(seoTitle);
      const seoDescription = data.data.news.seo_description;
      setSeoDescription(seoDescription);
      const seoKeyword = data.data.news.seo_keyword;
      setSeoKeyword(seoKeyword);

      const relatedNews = data.data.related_blogs;
      setRelatedNews(relatedNews);
    };
    blogMain();
  }, [blogSlug]);

  useEffect(() => {
    const listing = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.groups.abreco.nvds.in/api/news"
        );
        const blogData = data.data.list;
        // if (Array.isArray(blogLists)) {
        setBlogData(blogData);
        // }
      } catch (error) {
        console.error("API Error:", error);
      }
    };
    listing();
    setContentLoaded(true);
  }, [blogSlug]);

  const handleBlogClick = (blog) => {
    navigate(`/news/${blog.slug}`, {});
  };

  return (
    <>
      <Helmet>
        <style>
          {`
            nav#site-nav .navbar-brand img {width: 135px;position: relative;top: 0px;left: 0;}
            nav#site-nav.fixedHeader .navbar-brand img {top: 0px;}
            .fixed-navbar-nav {transform: translate(-50%, -50%) rotate(-90deg);transform-origin: unset;bottom: unset;top: 40%;}
            @media (min-width: 1369px) and (max-width: 1600px) {
              nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
            }
            @media (min-width: 1201px) and (max-width: 1368px) {
              nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
            }
            @media (min-width: 993px) and (max-width: 1200px) {
              nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
            }
            @media (max-width: 992px) {
              nav#site-nav .navbar-brand, nav#site-nav.fixedHeader .navbar-brand {padding:0;}
              nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 100px;top: 10px;left: 15px;position:absolute;}
            }
            @media (max-width: 767px) {
              nav#site-nav .navbar-brand, nav#site-nav.fixedHeader .navbar-brand {padding:0;}
              nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 100px;top: 5px;left: 15px;position:absolute;}
            }
            @media (max-width: 600px) {
              nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 90px;top: 10px;left: 10px;position:absolute;}
            }
          `}
        </style>
      </Helmet>

      <section className='pageTitle news newsDetailsPage'>
        <div className='container'>

          <div className='data'>
            <div className="text-center"><img src={`${baseUrl}${image}`} alt="images" loading="lazy" /></div>
            <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
            <p dangerouslySetInnerHTML={{ __html: content }}></p>
          </div>

        </div>
      </section>

      {relatedNews && relatedNews.length > 0 && (
        <section className="content related-blog pageSubTitle">
          <div className='container'>
            <div className='data no-padding'>
              <p>Recent Updates</p>
              <h2>OUR <span>NEWS</span></h2>
            </div>
          </div>
          <div
            className="container justify-content-center blog-wrapr">
            {relatedNews &&
              relatedNews.map((blog, index) => (
                <div className="item">
                  <div
                    key={index}
                    onClick={() => handleBlogClick(blog)}
                    className="link-whole"
                    rel="noopener noreferrer"
                  >
                  </div>
                  <div>
                    <img src={baseUrl + blog.image} alt={blog.title} />
                  </div>
                  <h4 dangerouslySetInnerHTML={{ __html: blog.title }}></h4>
                </div>
              ))}
          </div>
        </section>)}

      <Footer />

      <Helmet>
        {seoTitle !== null && <title>{seoTitle}</title>}
        {seoDescription !== null && (
          <meta name="description" content={seoDescription} />
        )}
        {seoKeyword !== null && (
          <meta name="keywords" content={seoKeyword} />
        )}
      </Helmet>
    </>
  )
}

export default NewsDetails