import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Footer from "../../components/footer/Footer";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './csr.css';

const CSR = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [csrSlider, setCSRSlider] = useState({});
    const [csrContent, setCSRContent] = useState({});
    const [csrList, setCSRList] = useState({});
    const [loading, setLoading] = useState(true);

    const [blogLists, setBlogLists] = useState([]);
    const [blogging, setBlogging] = useState([]);
    const navigate = useNavigate();
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        const csrSliderApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/csr"
                );

                const csrSlider = data.data.banner || {};
                setCSRSlider(csrSlider);

                const csrContent = data.data.content || {};
                setCSRContent(csrContent);

                const csrList = data.data.list || {};
                setCSRList(csrList);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        csrSliderApi();
    }, []);

    useEffect(() => {
        const blogMain = async () => {
            const { data } = await axios.get(
                "https://backend.groups.abreco.nvds.in/api/csr"
            );
            const blogLists = data.data.list;
            setBlogLists(blogLists);
            const blogging = data.data.list;
            setBlogging(blogging);
        };
        blogMain();
        setContentLoaded(true);
    }, []);

    const handleBlogClick = (list) => {
        navigate(`/csr-activities/${list.slug}`, {});
    };

    if (loading) {
        return <div></div>;
    }

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const itemsa = [
        <div className="item">
            <img src={baseUrl + csrSlider.image} alt="Abreco Group" />
            <div className="slide-content" data-aos="fade-up"><h2 dangerouslySetInnerHTML={{ __html: csrSlider.title }}></h2><span>{csrSlider.sub_title}</span></div>
        </div>
    ];

    const responsivea = {
        0: { itemsa: 1 },
        568: { itemsa: 1 },
        1024: { itemsa: 1 },
        1200: { itemsa: 1 },
        1368: { itemsa: 1 },
    };

    return (
        <>
            {/* PAGE BANNER */}
            <section className='siteBanner'>
                <AliceCarousel
                    items={itemsa}
                    responsive={responsivea}
                    disableDotsControls
                    disableButtonsControls
                />
                <div className='slanding1'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
                <div className='slanding2'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
            </section>

            <section className='pageSubTitle'>
                <div className='container'>
                    <div className='data' data-aos="fade-up">
                        <div id="dot-1">
                            <div className="dot-pattern-ten-six" data-aos="fade-up">
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            </div>
                        </div>
                        {csrContent?.title ? (
                            <>
                                <h2 dangerouslySetInnerHTML={{ __html: csrContent.title }} data-aos="fade-up"></h2>
                                <p dangerouslySetInnerHTML={{ __html: csrContent.sub_content }} data-aos="fade-up"></p>
                            </>
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </section>

            <section className='pageTitle csr-activities'>
                <div className='container'>
                    <div className='data'>
                        <div className="dots dot-pattern-ten-six xl" data-aos="fade-up">
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        </div>
                        <p data-aos="fade-up">Our Recent </p>
                        <h2 data-aos="fade-up"><span>CSR</span> ACTIVITIES</h2>
                    </div>

                    <div className='locationsBoxes'>
                        {csrList.map((list, index) => (
                            <>
                                <div className='item' key={index} data-aos="fade-up">
                                    <div>
                                        <img src={baseUrl + list.image} alt="Abreco Group" />
                                    </div>
                                    <p>{list.title}</p>
                                    <small>{truncateText(list.content, 120)}</small>
                                    {/* <Link to="">Link</Link> */}
                                    <div onClick={() => handleBlogClick(list)} className="link-whole" rel="noopener noreferrer">CONTINUE</div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />

            {csrContent && (
                <Helmet key={csrContent.uuid}>
                    {csrContent.seo_title !== null && <title>{csrContent.seo_title}</title>}
                    {csrContent.seo_description !== null && (
                        <meta name="description" content={csrContent.seo_description} />
                    )}
                    {csrContent.seo_keyword !== null && (
                        <meta name="keywords" content={csrContent.seo_keyword} />
                    )}
                </Helmet>
            )}
        </>
    )
}

export default CSR