import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import NotFoundImg from '../../images/notfound.webp';

import './notfound.css';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <style>
          {`
          nav#site-nav,
          .mobileMenuWrapper,
          ul.fixed-navbar-nav,
          footer,
          .scroll-to-top {
            display: none !important;
          }
        `}
        </style>
      </Helmet>
      <section className='pageSubTitle notfound'>
        <div className='container'>
          <div>
            <img src={NotFoundImg} alt='Not Found' />
            <h1><span>404</span> - Page Not Found</h1>
            <p>The page you are looking for might have been removed, <br />had its name changed or is temporarily unavailable.</p>
            <Link to='/' className='link'>Back to Home</Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default NotFound