import React from 'react';

import './harmburger.css';

const Harmburger = ({ show, handleClose, children }) => {
    return (
        <div className={`popup ${show ? 'show' : ''}`}>
            <div className="popup-content">
                <span className="close-btn" onClick={handleClose}>&times;</span>
                {children}
            </div>
        </div>
    )
}

export default Harmburger