import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './careerTabs.css';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

const CareerTabs = () => {
    const [activeTab, setActiveTab] = useState('');
    const [showButtons, setShowButtons] = useState(false);
    const tabListRef = useRef(null);
    const [brandList, setBrandList] = useState([]);
    const [subBrandList, setSubBrandList] = useState({});
    const [jobOpeningList, setJobOpeningList] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkOverflow = () => {
            if (tabListRef.current) {
                setShowButtons(tabListRef.current.scrollWidth > tabListRef.current.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);

    const scrollLeft = () => {
        if (tabListRef.current) {
            tabListRef.current.scrollBy({ left: -150, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (tabListRef.current) {
            tabListRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        }
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        const fetchBrandList = async () => {
            try {
                const { data } = await axios.get("https://backend.groups.abreco.nvds.in/api/career-list");
                const brandList = data.data.brand || [];
                setBrandList(brandList);

                const subBrandList = {};
                const jobOpeningList = {};
                brandList.forEach(brand => {
                    subBrandList[brand.uuid] = brand.sub_brands_lists || [];
                    jobOpeningList[brand.uuid] = brand.sub_brands_lists
                        ? brand.sub_brands_lists.reduce((acc, subBrand) => acc.concat(subBrand.careers_lists || []), [])
                        : [];
                });
                setSubBrandList(subBrandList);
                setJobOpeningList(jobOpeningList);

                if (brandList.length > 0) {
                    setActiveTab(`tab${brandList[0].uuid}`);
                }
            } catch (error) {
                console.error("Error fetching data", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchBrandList();
    }, []);

    const handleJobClick = (job) => {
        navigate(`/apply-now/${job.slug}`);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    return (
        <div className='careertabs'>
            <div className="d-flex align-items-center position-relative" data-aos="fade-up">
                {/* {showButtons && ( */}
                    <button className="scroll-button left" onClick={scrollLeft}>
                        <SlArrowLeft />
                    </button>
                {/* )} */}
                <div className="tabs-container flex-grow-1 overflow-hidden">
                    <div
                        className="nav nav-tabs d-flex overflow-auto"
                        ref={tabListRef}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {brandList.map((list) => (
                            <a
                                className={`nav-link ${activeTab === `tab${list.uuid}` ? 'active custom-active-tab' : ''}`}
                                onClick={() => handleTabClick(`tab${list.uuid}`)}
                                key={list.uuid}
                            >
                                {list.home_title}
                            </a>
                        ))}
                    </div>
                </div>
                {/* {showButtons && ( */}
                    <button className="scroll-button right" onClick={scrollRight}>
                        <SlArrowRight />
                    </button>
                {/* )} */}
            </div>
            <div className="tab-content mt-3">
                {brandList.map((list) => (
                    <div
                        className={`tab-pane fade ${activeTab === `tab${list.uuid}` ? 'show active' : ''}`}
                        id={`tab${list.uuid}`}
                        key={list.uuid}
                    >
                        {jobOpeningList[list.uuid] && jobOpeningList[list.uuid].length > 0 ? (
                            jobOpeningList[list.uuid].map((job, index) => (
                                <div className='box' key={index} data-aos="fade-up">
                                    <h4>{job.title}</h4>
                                    <p dangerouslySetInnerHTML={{ __html: job.description }}></p>
                                    <div className='excerpt'>
                                        <div>
                                            {/* {job.position && ( */}
                                                <p className="text-black mb-2">Company: <span className='text-grey'>{job.brand_name}</span></p>
                                            {/* )} */}
                                            {job.position && (
                                                <p className="text-black mb-2">Experience: <span className='text-grey'>{job.position}</span></p>
                                            )}
                                            <p className="text-black mb-0">Posted on: <span className='text-grey'>{formatDate(job.created_at)}</span></p>
                                        </div>
                                        <div className='link-whole'>
                                            <span onClick={() => handleJobClick(job)} rel="noopener noreferrer">APPLY NOW</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div style={{
                                background: '#eeeeee',
                                paddingTop: '50px',
                                paddingRight: '15px',
                                paddingBottom: '50px',
                                paddingLeft: '15px',
                                borderRadius: '5px',
                                color: '#ee2346',
                                textTransform: 'capitalize',
                                marginBottom: "100px"
                            }}>No job openings available</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CareerTabs;