import React, { useState, useEffect } from "react";

import HomeSlider from '../../components/homeSlider/HomeSlider';
import HomePageSubTitle from "../../components/HomePageSubTitle";
import HomePageSubTitleWithBanner from "../../components/HomePageSubTitleWithBanner";
import Brands from '../../components/brands/Brands';
import Join from '../../components/join/Join';
import Timeline from '../../components/timeline/Timeline';
import Ceo from '../../components/ceo/Ceo';
import News from '../../components/news/News';
import LifeAt from '../../components/lifeat/LifeAt';
import Clients from '../../components/clients/Clients';
import Locations from '../../components/locations/Locations';
import Footer from "../../components/footer/Footer";
import AnimatedComponent from '../../components/AnimatedComponent';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

  useEffect(() => {
      AOS.init({
        duration: 1000, // Animation duration (in milliseconds)
      });
  }, []);

  return (
    <>

      <HomeSlider />

      <HomePageSubTitle />

      <HomePageSubTitleWithBanner />

      <Brands />

      <Join />

      <Timeline />

      <Ceo />

      <News />

      <LifeAt />

      <Clients />

      <Locations />

      <Footer />

    </>
  )
}

export default Home