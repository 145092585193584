import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import './locations.css';

import { IoLocationSharp } from "react-icons/io5";

const Locations = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [locations, setLocations] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const locationsApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/home"
                );

                const locations = data.data.Location || {};
                setLocations(locations);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        locationsApi();
    }, []);

    if (loading) {
        return <div></div>;
    }

    return (
        <section className='pageTitle'>
            <div className='container'>
                    <div className='data' data-aos="fade-up">
                        <div className="dots dot-pattern-ten-six xl">
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        </div>
                        <p>We are </p>
                        <h2><span>WELL</span> NETWORKED</h2>
                    </div>

                <div className='locationsBoxes'>
                    {locations.map((list, index) => (
                        <>
                            <div className='item' key={index} data-aos="fade-up">
                                <div>
                                    <img src={baseUrl + list.image} alt="Abreco Group" />
                                </div>
                                <h5><IoLocationSharp />{list.location_name}</h5>
                                <Link to={list.location_url}>Link</Link>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </section >
    )
}

export default Locations