import React, { useState, useEffect } from "react";
import axios from "axios";
import './ceo.css';

import AnimatedComponent from '../AnimatedComponent';

import Quote from "../../images/quote.webp";

const Ceo = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [ceoMessage, setCeoMessage] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const ceoMessageApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/home"
                );

                const ceoMessage = data.data.dream || {};
                setCeoMessage(ceoMessage);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        ceoMessageApi();
    }, []);

    if (loading) {
        return <div></div>;
    }

    return (
        <>
            <section className='pageTitle ceo'>
                <div className='container-fluid'>
                    <div className="dots dot-pattern-ten-six xl">
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                    </div>
                    <div className='data'>
                        <div className='authorWrapper'>
                                <div className='title' data-aos="fade-up">
                                    {ceoMessage?.title ? (
                                        <>
                                            <h2 dangerouslySetInnerHTML={{ __html: ceoMessage.title }}></h2>
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                </div>
                                <div className='quote'>
                                    <img src={Quote} alt="Quote"  data-aos="fade-up" />
                                    {ceoMessage?.quote ? (
                                        <>
                                            <p dangerouslySetInnerHTML={{ __html: ceoMessage.quote }} data-aos="fade-up"></p>
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                </div>
                                
                                <div className='content' data-aos="fade-up">
                                    {ceoMessage?.content ? (
                                        <>
                                            <p dangerouslySetInnerHTML={{ __html: ceoMessage.content }}></p>
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                </div>

                            <div className='authorBox' data-aos="fade-up">
                                {ceoMessage?.content ? (
                                    <>
                                        <h3 dangerouslySetInnerHTML={{ __html: ceoMessage.author }}></h3>
                                        <p dangerouslySetInnerHTML={{ __html: ceoMessage.position }}></p>
                                    </>
                                ) : (
                                    <p></p>
                                )}
                            </div>

                        </div>
                        <div className='img' data-aos="fade-up">
                            <div className='title'>
                                {ceoMessage?.title ? (
                                    <>
                                        <h2 dangerouslySetInnerHTML={{ __html: ceoMessage.title }}></h2>
                                    </>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                            <AnimatedComponent animation="animate__fadeIn" delay=".1s" duration ="1s">
                                <div className='after'>
                                    {ceoMessage?.image ? (
                                        <>
                                            <img src={baseUrl + ceoMessage.image} className='ceoImage' alt="CEO" />
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                </div>
                            </AnimatedComponent>
                            {/* <div className='slanding1'>
                                <AnimatedComponent animation="animate__fadeInUpBig" delay=".1s" duration ="1s">
                                    <div className='inner'></div>
                                </AnimatedComponent>
                            </div>
                            <div className='slanding2'>
                                <AnimatedComponent animation="animate__fadeInUpBig" delay=".1s" duration ="1s">
                                    <div className='inner'></div>
                                </AnimatedComponent>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ceo