import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import ThankYouImg from '../../images/thankyou.webp';

import './thankYou.css';

const ThankYou = () => {

    const [siteFooter, setFooter] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const siteFooterApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/general"
                );

                const siteFooter = data.data.general || {};
                setFooter(siteFooter);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        siteFooterApi();
    }, []);

    if (loading) {
        return <div></div>;
    }

    return (
        <>
            <Helmet>
                <style>
                    {`
                        nav#site-nav,
                        .mobileMenuWrapper,
                        ul.fixed-navbar-nav,
                        footer,
                        .scroll-to-top {
                            display: none !important;
                        }
                    `}
                </style>
            </Helmet>
            <section className='pageSubTitle thank-you'>
                <div className='container'>
                    <div>
                        <img src={ThankYouImg} alt='Not Found' />
                        <h1>Thank <span>You!</span></h1>
                        <p>Thank you for your enquiry! Our team will reach out to you soon. <br />
                            If you have any additional questions, please feel free to <br />
                            reach out to us at <a href={`tel:${siteFooter.mobile.replace(/\s/g, '')}`}>{siteFooter.mobile}</a>. We are here to assist you every step of the way.</p>
                        <Link to='/' className='link'>Back to Home</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ThankYou