import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Harmburger from '../harmburger/Harmburger';
import BackToTop from '../backToTop';
import { Helmet } from 'react-helmet';

import AOS from 'aos';
import 'aos/dist/aos.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css/animate.min.css';
import './navbar.css';

import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import harmburgerIcon from "../../images/menu.webp";
import menuslide from "../../images/menuslide.webp";
import { useNavigate } from "react-router-dom";

const Navbar = () => {

  const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
  const [siteHeader, setHeader] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [listloading, setListLoading] = useState(true);

  useEffect(() => {
    const siteHeaderApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.groups.abreco.nvds.in/api/general"
        );
        const siteHeader = data.data.general || {};
        setHeader(siteHeader);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    siteHeaderApi();
  }, []);

  useEffect(() => {
      const brandListApi = async () => {
          try {
              const { data } = await axios.get(
                  "https://backend.groups.abreco.nvds.in/api/brand-list"
              );
              const brandList = data.data.business_list || [];
              setBrandList(brandList);
          } catch (error) {
              console.error("Error fetching data", error);
          } finally {
              setListLoading(false);
          }
      };
      brandListApi();
  }, []);

  useEffect(() => {
      AOS.init({
        duration: 1000, // Animation duration (in milliseconds)
      });
  }, []);

  if (loading || listloading) {
      return <div></div>;
  }

  // POPUP START
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  // POPUP END

  // MENU START
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // MENU END

  return (
    <>
      <BackToTop />
      <Helmet>
        <link rel="icon" href={baseUrl + siteHeader.favicon} />
      </Helmet>
      <nav id='site-nav' className="navbar navbar-expand-lg">
        <div className="container">
          <Link className='navbar-brand' to="/"><img src={baseUrl + siteHeader.logo} alt='Abreco Group' /></Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">

            <ul className="navbar-nav">
              <li className="nav-item"><Link className='nav-link' to="/">Home</Link></li>
              <li className="nav-item"><Link className='nav-link' to="/about-us">About Us</Link></li>
              <li className="nav-item"><Link className='nav-link' to="/brands">Brands</Link></li>
              <li className="nav-item"><Link className='nav-link' to="/careers">Careers</Link></li>
              <li className="nav-item"><Link className='nav-link' to="/contact-us">Contact Us</Link></li>
            </ul>
            <div className="harmburgerMenu">
              <button onClick={togglePopup}><img src={harmburgerIcon} alt="Menu" /></button>
              <Harmburger show={showPopup} handleClose={togglePopup}>
                <div className='container'>
                  <div className='outerWrapper'>
                    <div className='logoWrapper'>
                      <img src={baseUrl + siteHeader.logo} alt='Abreco Group' />
                    </div>
                    <div className='menuWrapper'>
                      <ul>
                        <li className='menuTitle'><span><a href="/brands">BRANDS</a></span>
                          <ul>
                            {brandList.map((list, index) => (
                              <li><a href={`/brands/${list.slug}`} rel="noopener noreferrer">{list.home_title}</a></li>
                            ))}
                          </ul>
                        </li>
                        <li className='menuTitle'><span><a href="/life-at-abreco">LIFE AT ABRECO</a></span>
                          <ul>
                            <li><a href="/life-at-abreco/#testimonials">Video Showreel</a></li>
                            <li><a href="/gallery">Image Gallery</a></li>
                          </ul>
                        </li>
                        <li className='menuTitle'><span><a href="/careers">CAREERS</a></span>
                          <ul>
                            <li><a href="/careers">Job Opening</a></li>
                          </ul>
                        </li>
                        <li className='menuTitle'><span><a href="/life-at-abreco">Quick Links</a></span>
                          <ul>
                            <li><a href="/csr-activities">CSR Activities</a></li>
                            <li><a href="/news">News</a></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className='aboutWrapper'>
                      <p>Abreco group is a global enterprise, headquartered in UAE, <br />comprising 25+ companies across fourteen verticals.</p>
                      <img src={menuslide} alt="Abreco Group" />
                    </div>
                  </div>
                </div>
              </Harmburger>
            </div>
          </div>
        </div>
      </nav>

      <div className="mobileMenuWrapper">
        <nav>
          <input type="checkbox" id="menu" name="menu" className="m-menu__checkbox" />
          <label className="m-menu__toggle" htmlFor="menu" id="harm">
            <img src={harmburgerIcon} alt="Menu" />
          </label>
          <label className="m-menu__overlay" htmlFor="menu"></label>

          <div className="m-menu">
            <div className="m-menu__header">
              <label className="m-menu__toggle" htmlFor="menu">
                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </label>
              <span>ABRECO GROUP</span>
            </div>
            <ul className="primaryy">
              <li><a className='nav-link' href="/">Home</a></li>
              <li><a className='nav-link' href="/about-us">About Us</a></li>
              <li><a className='nav-link' href="/brands">Brands</a></li>
              {/* <li>
                <label className="a-label__chevron" htmlFor="item-brands"><a className='nav-link px-0' href="/brands">Brands</a></label>
                <input type="checkbox" id="item-brands" name="item-brands" className="m-menu__checkbox" />
                <div className="m-menu">
                  <div className="m-menu__header">
                    <label className="m-menu__toggle" htmlFor="item-brands">
                      <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs">
                        <path d="M19 12H6M12 5l-7 7 7 7" />
                      </svg>
                    </label>
                    <span>Brands</span>
                  </div>
                  <ul>
                    {brandList.map((list, index) => (
                      <li><a href={`/brands/${list.slug}`} rel="noopener noreferrer">{list.home_title}</a></li>
                    ))}
                  </ul>
                </div>
              </li> */}
              <li><a className='nav-link' href="/life-at-abreco">Life At Abreco</a></li>
              {/* <li>
                <label className="a-label__chevron" htmlFor="item-life-at-abreco"><a className='nav-link px-0' href="/life-at-abreco">Life At Abreco</a></label>
                <input type="checkbox" id="item-life-at-abreco" name="item-life-at-abreco" className="m-menu__checkbox" />
                <div className="m-menu">
                  <div className="m-menu__header">
                    <label className="m-menu__toggle" htmlFor="item-life-at-abreco">
                      <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs">
                        <path d="M19 12H6M12 5l-7 7 7 7" />
                      </svg>
                    </label>
                    <span>Life At Abreco</span>
                  </div>
                  <ul>
                    <li><a className='nav-link' href="/life-at-abreco">Video Showreel</a></li>
                    <li><a className='nav-link' href="/gallery">Image Gallery</a></li>
                  </ul>
                </div>
              </li> */}
              <li><a className='nav-link' href="/csr-activities">CSR Activities</a></li>
              <li><a className='nav-link' href="/careers">Careers</a></li>
              <li><a className='nav-link' href="/news">News</a></li>
              <li><a className='nav-link' href="/gallery">Image Gallery</a></li>
              <li><a className='nav-link' href="/contact-us">Contact Us</a></li>
              <li>
                <label className="a-label__chevron" htmlFor="item-follow-us">Follow Us on</label>
                <input type="checkbox" id="item-follow-us" name="item-follow-us" className="m-menu__checkbox" />
                <div className="m-menu">
                  <div className="m-menu__header">
                    <label className="m-menu__toggle" htmlFor="item-follow-us">
                      <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs">
                        <path d="M19 12H6M12 5l-7 7 7 7" />
                      </svg>
                    </label>
                    <span>Follow Us on</span>
                  </div>
                  <ul>
                    {siteHeader && siteHeader.facebook && (
                      <li className="nav-item"><a className='nav-link' href={siteHeader.facebook} target="_blank"><FaFacebookF /> Facebook</a></li>
                    )}
                    {siteHeader && siteHeader.twitter && (
                      <li className="nav-item"><a className='nav-link' href={siteHeader.twitter} target="_blank"><FaXTwitter /> Twitter</a></li>
                    )}
                    {siteHeader && siteHeader.instagram && (
                      <li className="nav-item"><a className='nav-link' href={siteHeader.instagram} target="_blank"><FaInstagram /> Instagram</a></li>
                    )}
                    {siteHeader && siteHeader.youtube && (
                      <li className="nav-item"><a className='nav-link' href={siteHeader.youtube} target="_blank"><FaYoutube /> Youtube</a></li>
                    )}
                    {siteHeader && siteHeader.linkdln && (
                      <li className="nav-item"><a className='nav-link' href={siteHeader.linkdln} target="_blank"><FaLinkedinIn /> LinkedinIn</a></li>
                    )}
                  </ul>
                </div>
              </li>
              <li className='aboutWrapper'>
                <p>Abreco group is a global enterprise, headquartered in UAE, comprising 25+ companies across fourteen verticals.</p>
                <img src={menuslide} alt="Abreco Group" />
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <ul className="fixed-navbar-nav">
        <li className="nav-item"><Link className='nav-link' to="/careers">CAREERS</Link></li>
        {siteHeader && siteHeader.facebook && (
          <li className="nav-item"><a className='nav-link' href={siteHeader.facebook} target="_blank"><FaFacebookF /></a></li>
        )}
        {siteHeader && siteHeader.twitter && (
          <li className="nav-item"><a className='nav-link' href={siteHeader.twitter} target="_blank"><FaXTwitter /></a></li>
        )}
        {siteHeader && siteHeader.instagram && (
          <li className="nav-item"><a className='nav-link' href={siteHeader.instagram} target="_blank"><FaInstagram /></a></li>
        )}
        {siteHeader && siteHeader.youtube && (
          <li className="nav-item"><a className='nav-link' href={siteHeader.youtube} target="_blank"><FaYoutube /></a></li>
        )}
        {siteHeader && siteHeader.linkdln && (
          <li className="nav-item"><a className='nav-link' href={siteHeader.linkdln} target="_blank"><FaLinkedinIn /></a></li>
        )}
        <li className="nav-item"><Link className='nav-link' to="/life-at-abreco">LIFE AT ABRECO</Link></li>
      </ul>
    </>
  )
}

export default Navbar