import React from 'react';

const SimpleAnimatedComponent = ({ animation, id, children, delay = '0s', duration = '1s' }) => {
  const style = {
    animationDelay: delay,
    animationDuration: duration,
  };

  return (
    <div id={id} className={`animate__animated ${animation}`} style={style}>
      {children}
    </div>
  );
};

export default SimpleAnimatedComponent;