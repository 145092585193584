import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import AnimatedComponent from '../../components/AnimatedComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './brands.css';

const Brands = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [loading, setLoading] = useState(true);

    const [blogLists, setBlogLists] = useState([]);
    const [blogging, setBlogging] = useState([]);
    const navigate = useNavigate();
    const [contentLoaded, setContentLoaded] = useState(false);

    const [brandSetting, setBrandSettings] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [listloading, setListLoading] = useState(true);

    useEffect(() => {
        const blogMain = async () => {
          const { data } = await axios.get(
            "https://backend.groups.abreco.nvds.in/api/brand-list"
          );
          const blogLists = data.data.business_list;
          setBlogLists(blogLists);
          const blogging = data.data.business_list;
          setBlogging(blogging);
        };
        blogMain();
        setContentLoaded(true);
      }, []);

    const handleBlogClick = (list) => {
      navigate(`/brands/${list.slug}`, {});
    };

    useEffect(() => {
        const brandSettingApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/brand-settings"
                );

                const brandSetting = data.data.business_settings || {};
                setBrandSettings(brandSetting);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        brandSettingApi();
    }, []);

    useEffect(() => {
        const brandListApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/brand-list"
                );

                const brandList = data.data.business_list || [];
                setBrandList(brandList);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setListLoading(false);
            }
        };
        brandListApi();
    }, []);

    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration (in milliseconds)
        });
    }, []);

    if (loading || listloading) {
        return <div></div>;
    }

  return (
    <>
        {/* <AnimatedComponent animation="animate__fadeInUp" delay=".1s" duration ="1s"> */}
            <section className='brands'>
                <div className='container'>
                    <div className='row'>
                        <div className='data'>
                            <div className='item' data-aos="fade-up">
                                {brandSetting?.title ? (
                                    <>
                                        <p>We Believe in Ethics</p>
                                        <h2 dangerouslySetInnerHTML={{ __html: brandSetting.title }}></h2>
                                    </>
                                ) : (
                                    <p></p>
                                )}
                                {brandSetting?.content ? (
                                    <>
                                        <small dangerouslySetInnerHTML={{ __html: brandSetting.content }}></small>
                                    </>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                            {brandList.map((list, index) => (
                                <>
                                    <div className='item' key={index} data-aos="fade-up">
                                        <div className='imgWrapper'><img src={baseUrl + list.thumbnail} alt="Abreco Group" /></div>
                                        <h5>{list.home_title}</h5>
                                        <div onClick={() => handleBlogClick(list)} className="link-whole" rel="noopener noreferrer">CONTINUE</div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        {/* </AnimatedComponent> */}
    </>
  )
}

export default Brands