import React, { useState, useEffect } from "react";

import ArrowUp from '../images/arrows-long-up-svgrepo-com.svg';
import { FaWhatsapp } from "react-icons/fa";

const BackToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    setShowScroll(true);
    // const handleScroll = () => {
    //   if (window.pageYOffset > 0) {
    //     setShowScroll(true);
    //   } else {
    //     setShowScroll(false);
    //   }
    // };

    // window.addEventListener('scroll', handleScroll);

    // return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {showScroll && (
        <>
          <div className="scroll-to-top" onClick={scrollToTop}>
            <img src={ArrowUp} alt="Go to top" />
          </div>
          {/* <div className="fixed-button">
            <a href="#" target="_blank"><FaWhatsapp /></a>
          </div> */}
        </>
      )}
    </div>
  )
}
export default BackToTop;