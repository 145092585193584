import React, { useState, useEffect } from "react";
import axios from "axios";
import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';

import './homeslider.css';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const HomeSlider = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [homeSlider, setHomeSlider] = useState({});
    const [actualSlide, setActualSlide] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const homeSliderApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/home"
                );

                const homeSlider = data.data.home_sliders || {};
                setHomeSlider(homeSlider);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        homeSliderApi();
    }, []);

    if (loading) {
        return <div></div>;
    }

    const itemsa = homeSlider.map((list, index) => (
        <div className="item" key={index}>
            <img src={baseUrl + list.image} alt="Abreco Group" />
            <div className="slide-content"><h2 dangerouslySetInnerHTML={{ __html: list.title }}></h2><span>{list.sub_title}</span></div>
        </div>
    ));

    const responsive = {
        0: { itemsa: 1 },
        568: { itemsa: 1 },
        1024: { itemsa: 1 },
        1200: { itemsa: 1 },
        1368: { itemsa: 1 },
    };
    
    return (
        <>
            <section className='siteBanner'>

                    <AliceCarousel
                        mouseTracking
                        items={itemsa}
                        autoPlay
                        autoPlayInterval={3000}
                        infinite
                        disableButtonsControls
                        animationType="fadeout"
                        autoPlayStrategy="none"
                        stopOnHover={false}
                        // disableDotsControls
                        responsive={responsive}
                    />

                <div className='slanding1'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig" duration ="2s">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
                <div className='slanding2'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig" duration ="2s">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
            </section>
        </>
    )
}

export default HomeSlider