import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Clients from '../../components/clients/Clients';
import Locations from '../../components/locations/Locations';
import Footer from "../../components/footer/Footer";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './contact.css';


const ContactUs = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [bannerSlider, setLifeAtAbrecoSlider] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const bannerSliderApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/contact"
                );

                const bannerSlider = data.data.contact || {};
                setLifeAtAbrecoSlider(bannerSlider);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        bannerSliderApi();
    }, []);


    if (loading) {
        return <div></div>;
    }

    const itemsa = [
        <div className="item">
            <img src={baseUrl + bannerSlider.image} alt="Abreco Group" />
            <div className="slide-content" data-aos="fade-up"><h2 dangerouslySetInnerHTML={{ __html: bannerSlider.title }}></h2><span>{bannerSlider.sub_title}</span></div>
        </div>
    ];

    const responsivea = {
        0: { itemsa: 1 },
        568: { itemsa: 1 },
        1024: { itemsa: 1 },
        1200: { itemsa: 1 },
        1368: { itemsa: 1 },
    };

    return (
        <>
            <section className='siteBanner'>
                <AliceCarousel
                    items={itemsa}
                    responsive={responsivea}
                    disableDotsControls
                    disableButtonsControls
                />
                <div className='slanding1'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
                <div className='slanding2'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
            </section>

            <Locations />

            <Clients />

            <Footer />

            {bannerSlider && (
                <Helmet key={bannerSlider.uuid}>
                    {bannerSlider.seo_title !== null && <title>{bannerSlider.seo_title}</title>}
                    {bannerSlider.seo_description !== null && (
                        <meta name="description" content={bannerSlider.seo_description} />
                    )}
                    {bannerSlider.seo_keyword !== null && (
                        <meta name="keywords" content={bannerSlider.seo_keyword} />
                    )}
                </Helmet>
            )}
        </>
    )
}

export default ContactUs