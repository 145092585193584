import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation  } from 'react-router-dom'

import Navbar from './components/navbar/Navbar';
import CsrDetail from './components/CsrDetails';
import NewsDetails from './components/NewsDetails';
import BrandDetails from './components/BrandDetails';

import Home from './pages/home/Home';
import AboutUs from './pages/aboutUs/AboutUs';
import Brands from './pages/brands/Brands';
import LifeAtAbreco from './pages/lifeAtAbreco/LifeAtAbreco';
import CSR from './pages/csr/CSR';
import ContactUs from './pages/contactUs/ContactUs';
import News from './pages/news/News';
import Careers from './pages/careers/Careers';
import CareerDetails from './components/CareerDetails';
import Gallery from './pages/gallery/Gallery';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import ThankYou from './pages/thankYou/ThankYou';
import ThankYouForApplying from './pages/thankYou/ThankYouForApplying';
import NotFound from './pages/notFound/NotFound';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = () => {
  return (  
  // <GoogleReCaptchaProvider reCaptchaKey="6Ld2PfUpAAAAAOsRX3G6IyiZtRBh8YFNguvbG6Qa">
    <BrowserRouter basename={process.env.PUBLIC_URL}>

      <Navbar />

      <Routes>
          <Route index exact element={<Home/>} />
          <Route path='/about-us' element={<AboutUs/>} />
          <Route path='/brands' element={<Brands/>} />
          <Route path={"/brands/:slug"} element={<BrandDetails/>} />
          <Route path='/life-at-abreco' element={<LifeAtAbreco/>} />
          <Route path='/csr-activities' element={<CSR/>} />
          <Route path={"/csr-activities/:slug"} element={<CsrDetail/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/news' element={<News/>} />
          <Route path={"/news/:slug"} element={<NewsDetails/>} />
          <Route path='/careers' element={<Careers/>} />
          <Route path='/apply-now/:slug' element={<CareerDetails/>} />
          <Route path='/gallery' element={<Gallery/>} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/thank-you' element={<ThankYou/>} />
          <Route path='/thank-you-for-applying' element={<ThankYouForApplying/>} />
          <Route path='*' element={<NotFound/>} />
      </Routes>
      <ScrollToTop />
 
    </BrowserRouter>
    // </GoogleReCaptchaProvider>
  )
}

export default App