import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SimpleAnimatedComponent from './SimpleAnimatedComponent';
import AnimatedComponent from './AnimatedComponent';
import Footer from "./footer/Footer";
import { Helmet } from "react-helmet";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './brands/brands.css';

const BrandDetails = () => {
    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [image, setImage] = useState("");
    const [pageTitle, setPageTitle] = useState("");
    const [detailTitle, setDetailTitle] = useState("");
    const [detailLogo, setDetailLogo] = useState("");
    const [detailContent, setDetailContent] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [buttonlink, setButtonlink] = useState("");
    const [subBrands, setSubBrand] = useState([]);
    const [seoTitle, setSeoTitle] = useState("");
    const [seoDescription, setSeoDescription] = useState("");
    const [seoKeyword, setSeoKeyword] = useState("");
    const [loading, setLoading] = useState(true);
    
    const urlParts = window.location.href.split("/");
    const blogSlug = urlParts[urlParts.length - 1];
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`https://backend.groups.abreco.nvds.in/api/brand-list/${blogSlug}`);
                const brandData = data.data.bussiness_list;

                setTitle(brandData.title);
                setSubTitle(brandData.sub_title);
                setImage(brandData.image);
                setPageTitle(brandData.page_title);
                setDetailTitle(brandData.detail_title);
                setDetailLogo(brandData.detail_logo);
                setDetailContent(brandData.detail_content);
                setButtonText(brandData.detail_button_text);
                setButtonlink(brandData.detail_button_link);
                setSubBrand(data.data.brand || []);
                setSeoTitle(brandData.seo_title);
                setSeoDescription(brandData.seo_description);
                setSeoKeyword(brandData.seo_keyword);
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [blogSlug]);

    const handleBlogClick = (blog) => {
        navigate(`/blogs/${blog.slug}`, {});
    };

    const carouselItems = [
        <div className="item" key="carousel-item">
            <img src={`${baseUrl}${image}`} alt="Abreco Group" />
            <div className="slide-content" data-aos="fade-up">
                <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                <span>{subTitle}</span>
            </div>
        </div>
    ];

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
        1200: { items: 1 },
        1368: { items: 1 },
    };

    return (
        <>
            <section className='siteBanner'>
                <AliceCarousel
                    items={carouselItems}
                    responsive={responsive}
                    disableDotsControls
                    disableButtonsControls
                />
                <div className='slanding1'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
                <div className='slanding2'>
                    <SimpleAnimatedComponent animation="animate__fadeInUpBig">
                        <div className='inner'></div>
                    </SimpleAnimatedComponent>
                </div>
            </section>

            <section className='pageSubTitle brandDetails'>
                <div className='container'>
                    <div className='data' data-aos="fade-up">
                        <div id="dot-1">
                            <div className="dot-pattern-ten-six">
                                {[...Array(6)].map((_, i) => (
                                    <section key={i}>
                                        {[...Array(10)].map((_, j) => (
                                            <div key={j}></div>
                                        ))}
                                    </section>
                                ))}
                            </div>
                        </div>
                        <h2 dangerouslySetInnerHTML={{ __html: pageTitle }}></h2>
                    </div>
                </div>
            </section>

            <section className='pageSubTitle brandData'>
                <div className='container'>
                    {/* <div>
                        <a href={buttonlink} target="_blank" rel="noopener noreferrer">
                            <img src={`${baseUrl}${detailLogo}`} alt={title} />
                        </a>
                        <h2 dangerouslySetInnerHTML={{ __html: detailTitle }}></h2>
                        <p dangerouslySetInnerHTML={{ __html: detailContent }}></p>
                        <div className="text-center">
                            <a href={buttonlink} target="_blank" rel="noopener noreferrer">{buttonText}</a>
                            <Link to="/brands">BACK1</Link>
                        </div>
                    </div> */}

                    {subBrands.map((list, index) => (
                        <div key={index}>
                            {list?.logo ? (
                                <>
                                <a href={list.button_link} target="_blank" rel="noopener noreferrer">
                                    <img src={`${baseUrl}${list.logo}`} alt={list.title}  data-aos="zoom-in" />
                                </a>
                                </>
                            ) : (
                                <span></span>
                            )}
                            <h2 dangerouslySetInnerHTML={{ __html: list.title }} data-aos="fade-up"></h2>
                            <p dangerouslySetInnerHTML={{ __html: list.content }} data-aos="fade-up"></p>
                            <div className="text-center" data-aos="zoom-in">
                                <a href={list.button_link} target="_blank" rel="noopener noreferrer">{list.button_title}</a>&nbsp;
                                <Link to="/brands" style={{
                                    background: 'transparent',
                                    color: 'var(--primary)',
                                    textDecoration: 'underline',
                                }}>BACK TO BRANDS</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className='pageSubTitleWithBanner brandsCTA' >
                <div className='container' style={{ backgroundImage: `url(${baseUrl}${image})` }}>
                    <div className='data'>
                        <p>CONTACT</p>
                        <h2>FOR MORE QUERIES</h2>
                        <Link to='/contact-us' target="_blank" rel="noopener noreferrer">Get in Touch</Link>
                    </div>
                </div>
            </section>

            <Helmet>
                {seoTitle && <title>{seoTitle}</title>}
                {seoDescription && <meta name="description" content={seoDescription} />}
                {seoKeyword && <meta name="keywords" content={seoKeyword} />}
            </Helmet>

            <Footer />
        </>
    );
};

export default BrandDetails;
