import React, { useState, useEffect } from "react";
import axios from "axios";

const HomePageSubTitleWithBanner = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [pageSubTitleWithBackground, setPageSubTitleWithBackground] = useState({});
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const pageSubTitleApi = async () => {
        try {
          const { data } = await axios.get(
            "https://backend.groups.abreco.nvds.in/api/home"
          );
  
          const pageSubTitleWithBackground = data.data.inclusive || {};
          setPageSubTitleWithBackground(pageSubTitleWithBackground);
  
        } catch (error) {
          console.error("Error fetching data", error);
        } finally {
          setLoading(false);
        }
      };
      pageSubTitleApi();
    }, []);
  
    if (loading) {
      return <div></div>;
    }

  return (
    <>
      <section className='pageSubTitleWithBanner' data-aos="fade-up">
        <div className='container' style={{
          backgroundImage: `url(${baseUrl + pageSubTitleWithBackground.image})`,
        }}>
          <div className='data'>
              {pageSubTitleWithBackground?.title ? (
                <>
                {/* <h2 className="type" style={{ '--n': 53 }} dangerouslySetInnerHTML={{ __html: pageSubTitleWithBackground.title }} ></h2> */}
                  {/* data-aos="fade-in" */}
                  {/* style={{
                    textShadow: '0px 0 60px #000',
                  }}  */}

<span class="type" style={{ '--n': 53 }} dangerouslySetInnerHTML={{ __html: pageSubTitleWithBackground.title }} data-aos="fade-in" data-aos-delay="500"></span>
                </>
              ) : (
                <p></p>
              )}
          </div>
        </div>
      </section>

      <section className='pageSubTitle pt-0'>
        <div className='container'>
          <div className='data'>
            <div id="dot-2">
              <div className="dot-pattern-ten-six">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
            </div>
              {pageSubTitleWithBackground?.sub_title ? (
                <>
                  <p dangerouslySetInnerHTML={{ __html: pageSubTitleWithBackground.sub_title }} data-aos="fade-up"></p>
                </>
              ) : (
                <p></p>
              )}
          </div>
        </div>
      </section>
    </>
  )
}

export default HomePageSubTitleWithBanner