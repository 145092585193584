import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";

import './privacyPolicy.css';

const PrivacyPolicy = () => {

  const [privacyPolicy, setPrivacySlider] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const privacyPolicyApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.groups.abreco.nvds.in/api/privacy"
        );

        const privacyPolicy = data.data.privacy || {};
        setPrivacySlider(privacyPolicy);

      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    privacyPolicyApi();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <>
    <Helmet>
      <style>
        {`
        nav#site-nav .navbar-brand img {width: 135px;position: relative;top: 0px;left: 0;}
        nav#site-nav.fixedHeader .navbar-brand img {top: 0px;}
        .fixed-navbar-nav {transform: translate(-50%, -50%) rotate(-90deg);transform-origin: unset;bottom: unset;top: 40%;}
        @media (min-width: 1369px) and (max-width: 1600px) {
          nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
        }
        @media (min-width: 1201px) and (max-width: 1368px) {
          nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
        }
        @media (min-width: 993px) and (max-width: 1200px) {
          nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 120px;top: 0;left: 0;}
        }
        @media (max-width: 992px) {
          nav#site-nav .navbar-brand, nav#site-nav.fixedHeader .navbar-brand {padding:0;}
          nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 100px;top: 0;left: 0;}
        }
        @media (max-width: 600px) {
          nav#site-nav .navbar-brand img, nav#site-nav.fixedHeader .navbar-brand img {width: 90px;top: 0;left: 0;}
        }
      `}
      </style>
    </Helmet>
      <section className='pageTitle news newsDetailsPage policy'>
        <div className='container'>
          <div className='data' data-aos="fade-up">
            <h1 dangerouslySetInnerHTML={{ __html: privacyPolicy.title }}></h1>
            <div dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}></div>
          </div>
        </div>
      </section>

      <Footer />
      
    </>
  )
}

export default PrivacyPolicy